/**
 * Domyślne akcje URL do określonych czynności, rejestracja, logowanie itp.
 * @string akcja
 */
const ACTION = {
  REGISTER: 'users/register/',
  REGISTER_FIRST: 'users/register/first',
  REGISTER_SECOND: 'users/register/confirm',
  REGISTER_TOKEN_CHECK: 'users/register/tokenCheck',
  USER_DETAILS: 'users/details',
  USER_CHECK_SESSION: 'users/checkSession',
  CARD_DETAILS: 'cards/{cardNr}/details',
  CARD_LIST: 'users/cards',
  USER_UPDATE: 'users/update',
  LOGOUT: 'auth/logout/',
  LOGIN: 'auth/login',
  SHOPS: 'shops/',
  SHOPS_LIST: 'shops/list',
  PROMOTION_LIST: 'promotions/list?shop_id={ShopId}',
  CONTACT_SEND: 'contact/send',
  TRANSACTION_LIST: 'users/transactions?year={Year}&month={Month}',
  ORDER_LIST: 'users/orders?year={Year}&month={Month}',
  POINTS_EXTRA_LIST: 'users/pointsExtra?year={Year}&month={Month}',
  CMS_NAVIGATION: 'cms/navigation',
  CMS_PAGE: 'cms/seo/{CmsId}',
};
/**
 * dopisanie URL jako prefix
 */
Object.keys(ACTION).forEach((key) => {
  ACTION[key] = process.env.VUE_APP_API_URL + ACTION[key];
});

/**
 * eksport zmiennych
 */
export default {
  URL: process.env.VUE_APP_API_URL,
  ACTION,
};
