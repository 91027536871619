<template>
  <div id="app">
    <div class="w-100 h-100 position-absolute text-center loading-overlay" v-if="loading" >
      <div class="spinner-border loading-spinner" role="status" size="lg">
        <span class="sr-only">Ładowanie.....</span>
      </div>
    </div>
    <cookie-alert/>
    <notifications group="default"/>
    <navigation/>
    <install-prompt></install-prompt>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import InstallPrompt from '@/components/InstallPrompt.vue';
import CookieAlert from '@/components/CookieAlert.vue';

import UserService from '@/_services/user.service';
import PagesService from '@/_services/pages.service';

const userService = new UserService();
const pagesService = new PagesService();

export default {
  name: 'home',
  components: {
    Navigation,
    CookieAlert,
    Footer,
    InstallPrompt,
  },
  /**
     * tu ogolnie mozna ladowac wszystkie metody ktore mają sie wykonać przed inicjacją projektu
     * */
  beforeCreate() {
    /**
     * ładowanie nawigacji z api
     * */
    pagesService.getNavigation()
      .then((navigations) => {
        Object.values(navigations)
          .forEach((navigation) => {
            this.$store.state.navigation.push(navigation);
          });
      });
    /**
       * aby utrzymywać sesję przy życiu, co jakiś czas wysyłamy żądanie do serwera
       */
    userService.checkIsLogged();
    setInterval(() => {
      userService.checkIsLogged();
    }, 120000);
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style lang="scss">
  @import 'assets/scss/main.scss';
</style>
