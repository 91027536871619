import http from '@/_helpers/http';

export default class pagesService {
  constructor(cachingData = true) {
    this.cache = cachingData;
  }

  sendContactMessage(Form) {
    return http.axios.post(`${http.api.ACTION.CONTACT_SEND}`, Form)
      .then(response => response.data)
      .catch(error => error);
  }

  getNavigation() {
    return http.axios.get(`${http.api.ACTION.CMS_NAVIGATION}`).then(response => response.data.data);
  }

  getCmsPage(id) {
    return http.axios.get(`${http.api.ACTION.CMS_PAGE.replace('{CmsId}', id)}`).then((response) => {
      if (typeof response.data.data[0] === 'undefined') {
        return false;
      }
      this.setCache(response.data.data[0].translations[0], `CMS_${id}`);
      return response.data.data[0].translations[0];
    });
  }

  setCache(cacheData, variable, time = 36000) {
    const cache = cacheData;
    cache.cacheTime = Math.floor(Date.now() / 1000) - time;
    localStorage.setItem(variable, JSON.stringify(cache));
    return true;
  }


  isCached(variable) {
    if (!this.cache) {
      return false;
    }
    if (localStorage.getItem(variable) !== null) {
      return (this.getCached(variable).cacheTime < Math.floor(Date.now() / 1000));
    }
    return false;
  }

  getCached(variable) {
    return JSON.parse(localStorage.getItem(variable));
  }

  /**
   * pobiera listę promocji dla danego sklepu
   * @param id
   * @returns axios
   */
  getPromotionsList(id) {
    return http.axios.get(`${http.api.ACTION.PROMOTION_LIST.replace('{ShopId}', id)}`).then(response => response.data);
  }
}
