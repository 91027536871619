import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navigation: [],
    isLogged: false,
    loading: false,
    shops: null,
  },
  actions: {},
  getters: {
    isLogged: (state) => state.isLogged,
  },
  mutations: {


  },
});
