<template>
  <b-alert
    variant="primary"
    dismissible
    fade
    :show="showInstallBanner"
    @dismissed="showInstallBanner=false"
    class="w-100 fixed-top text-center install-prompt">
    <a href="#" @click="install" style="color:blue">Dodaj naszą aplikację na ekran główny</a>, aby być na bieżąco z promocjami!
  <b-link variant="link" class="float-right close-install-alert" @click="closePermanent()">Nie pokazuj więcej</b-link>
  </b-alert>
</template>
<script>
import cookies from '../_helpers/cookies';

let deferredPrompt;
export default {
  name: 'installPrompt',
  data() {
    return {
      showInstallBanner: false,
    };
  },
  created() {
    if (cookies.getCookie('installPromptClosedPermanently')) {
      return;
    }
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPrompt = e;
      this.showInstallBanner = true;
    });
  },
  methods: {
    install() {
      this.showInstallBanner = false;
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        deferredPrompt = null;
      });
    },
    closePermanent() {
      this.showInstallBanner = false;
      cookies.setCookie('installPromptClosedPermanently', '1', 365);
    },
  },
};
</script>
<style scoped>
  .install-prompt{
    top:85px;
    z-index:10;
    border-radius: 0;
  }
  .close-install-alert{
    font-size:12px;
  }
</style>
