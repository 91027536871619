import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueNotification from 'vue-notification';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './_helpers/http';
import serviceWorker from './serviceWorker';

Vue.prototype.$http = http.axios;
Vue.prototype.$bonus_points = process.env.VUE_APP_MARKETING_BONUS_POINTS;
Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(serviceWorker);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueNotification);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
